import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Hero = () => {
  const { t } = useTranslation()
  return (
    <section className="grid grid-cols-1 sm:grid-cols-5 gap-10 items-center py-24 sm:py-32 md:py-24 lg:py-24 xl:py-32 2xl:py-40">
      <div className="text-white flex flex-col gap-2 sm:gap-4 sm:col-span-5 text-center">
        <h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-7xl 2xl:text-8xl">
          {t('events-page.hero.title')}
        </h1>

        <p className="text-lg sm:text-1xl md:text-1xl xl:text-2xl 2xl:text-3xl">
          {t('events-page.hero.description')}
        </p>
      </div>
    </section>
  )
}
