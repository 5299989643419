import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'components/library'
import { EventsSection } from 'components/pages/events/events-section'
import { TranslationKey } from "types/translation"
import { CtaBlock } from 'components/common/cta-block'
import { Hero } from 'components/pages/events/events-hero'

type Events = {
    heading: TranslationKey
    data: {
        image: any
        name: TranslationKey
        place: TranslationKey
        duration: TranslationKey
        link: string
    }[]
}

type EventsData = {
    upcomingEvents: Events
    pastEvents: Events
}

let eventsUpdated = false;

const events: EventsData = {
    upcomingEvents: {
        heading: "events-page.events.upcomingEvents.heading",
        // Keep recent event at the start of an array
        data: [],
    },
    pastEvents: {
        heading: "events-page.events.pastEvents.heading",
        // Keep recent event at the start of an array
        data: [],
    },
};

const Events = ({ pageContext: { newEvents } }: any) => {
    newEvents.sort(function (a: any, b: any) {
        return new Date(b.EndDate).getTime() - new Date(a.EndDate).getTime();
    });

    if (!eventsUpdated) {
        newEvents?.forEach((e: any) => {
            if (e.Type.toLowerCase() === "upcoming") events.upcomingEvents.data.push({
                image: e.Image,
                name: e.Name,
                place: e.Place,
                duration: e.Duration,
                link: e.Link,
            });
            else if (e.Type.toLowerCase() === "past") events.pastEvents.data.push({
                image: e.Image,
                name: e.Name,
                place: e.Place,
                duration: e.Duration,
                link: e.Link,
            })
        })
        eventsUpdated = true
    }

    return (
        <main className="bg-events-hero-bg-mobile sm:bg-events-hero-bg-tab lg:bg-events-hero-bg bg-no-repeat bg-contain w-full -mt-28 sm:-mt-36">
            <Container className="flex flex-col py-14 sm:py-24 gap-10 px-10">
                <Hero />
                {events.upcomingEvents.data.length > 0 && <EventsSection
                    eventsHeading={events.upcomingEvents.heading}
                    eventsData={events.upcomingEvents.data}
                />}

                {events.pastEvents.data.length > 0 && <EventsSection
                    eventsHeading={events.pastEvents.heading}
                    eventsData={events.pastEvents.data}
                />}
            </Container>

            <CtaBlock />
        </main >
    );
}

export default Events;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
