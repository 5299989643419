import React from 'react'
import { Link } from 'gatsby'
import { TranslationKey } from 'types/translation'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export type EventsDetails = {
  image: any
  name: TranslationKey
  place: TranslationKey
  duration: TranslationKey
  link: string
}

type EventsHeading = { eventsHeading: TranslationKey }

type EventsCardProps = EventsDetails & EventsHeading

export const EventsCard = ({
  image,
  name,
  place,
  duration,
  link,
  eventsHeading
}: EventsCardProps) => {
  const { t } = useTranslation()
  return (
    <Link to={link} target="_blank">
      <div className="flex flex-col bg-white rounded-sm shadow-lg drop-shadow-lg transition duration-500 hover:scale-110 hover:shadow-xl">
        <div className="flex flex-center h-44 items-center justify-center">
          <img
            alt={t(name) as string}
            className={
              "object-contain max-h-44 overflow-hidden py-2 px-4 flex-none"
            }
            src={image}
          />
        </div>

        <div className="flex flex-col gap-2 bg-[#DEE9FD] p-8 flex-grow mb-10">
          <span className="text-md text-[#253858] flex-none uppercase tracking-widest">
            {t(eventsHeading) as string}
          </span>
          <hr className="w-8 h-px bg-[#8C8C8C] border-0" />
          <div className="text-2xl text-[#253858] flex-none truncate">
            {t(name) as string}
          </div>
          <p className="text-sm text-[#305CBD] flex-grow truncate">
            {t(place) as string}
          </p>
          <p className="text-sm text-[#305CBD] flex-grow truncate">
            {t(duration) as string}
          </p>
        </div>
      </div>
    </Link>
  )
}
